import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { Observable, merge, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FlashyStoreService } from '@flashy/store';

import {
  EFlashyCatalogType,
  FlashyCatalog,
  FlashyCatalogContent,
} from './catalog';

const cacheCatalogs = new Map<EFlashyCatalogType, FlashyCatalog[]>();

@Injectable({
  providedIn: 'root',
})
export class FlashyCatalogDataService extends EntityCollectionServiceBase<FlashyCatalog> {
  isLoading$ = merge(this.loading$, this.storeService.isLoading$);

  constructor(
    elementsFactory: EntityCollectionServiceElementsFactory,
    private storeService: FlashyStoreService
  ) {
    super('Catalog', elementsFactory);
  }

  getAllByType(type: EFlashyCatalogType): Observable<FlashyCatalog[]> {
    if (cacheCatalogs.has(type)) {
      return of(cacheCatalogs.get(type));
    }
    return this.getWithQuery({ type }).pipe(
      tap((catalogs) => {
        if (catalogs.length) {
          cacheCatalogs.set(type, catalogs);
        }
      })
    );
  }

  getCatalogContent(
    id: string,
    limit?: string
  ): Observable<FlashyCatalogContent[]> {
    return this.storeService.makeHttpRequest(
      'GET',
      `catalog/${id}/preview`,
      {},
      { limit }
    );
  }

  deleteAllItems(id: FlashyCatalog['id']): Observable<void> {
    return this.storeService.makeHttpRequest<void>(
      'DELETE',
      `catalog/${id}/items`
    );
  }

  getCatalogCategories(
    catalog: FlashyCatalog['id'],
    values?: string
  ): Observable<string[]> {
    return this.storeService.makeHttpRequest(
      'GET',
      'autocomplete/categories',
      {},
      { catalog, values }
    );
  }
}
